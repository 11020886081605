// import React from "react"
// import { Router, Redirect } from "@reach/router"
//
// const IndexPage =() => (
//     <React.Fragment>
//         <Router>
//             <Redirect noThrow
//                 from="/credentials/"
//                 to="/credentials/administratoroverview"
//             />
//         </Router>
//     </React.Fragment>
// )
// export default IndexPage

export default function Index() {
  if (typeof window !== 'undefined') {
    const langRegex = /(\/en\/|\/de\/|\/ja\/|\/es-MX\/|\/pt-BR\/)/g;
    const langFound = document.location.pathname.match(langRegex);
    if (langFound && langFound.length > 0) {
      const urlLang = langFound[0].substring(1, 3);
      window.location = `/${urlLang}/credentials/administratoroverview/`;
    } else {
      window.location = '/en/credentials/administratoroverview/';
    }
  }

  return null;
}
